function stickyTop() {
    const clientSections = document.querySelectorAll('.client-section');
    const observer = new IntersectionObserver(clients => {
        clients.forEach(client => {
            client.target.classList.toggle('show', client.isIntersecting);
        });
      }, { threshold: 0.2 }
    );
    clientSections.forEach(client => {
      observer.observe(client);
    });
}
export { stickyTop as default };