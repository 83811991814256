function closeModal() {
    if (document.querySelector('#iframeModal')) {
        // Stops modal video from playing when modal is closed
        var myModal = document.querySelector('#iframeModal');
        var myIframe = document.querySelector('iframe#hpmodal');

        myModal.addEventListener('hide.bs.modal', function (e) {
            var src = myIframe.src;
            myIframe.src = src; 
        });
    }
}

export { closeModal as default };