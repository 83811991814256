import { gsap } from "gsap";

function navigation() {
    var openButton = document.querySelector('.openButton');
    var logoSection = document.querySelector('.navbar div:first-child');

    let navLines = gsap.timeline({ paused:true, duration: 0.5})
    .to('.nav-toggler .line1', {y: 8, rotate: 45, backgroundColor:'#fff'}, 0)
    .to('.nav-toggler .line2', {width: 0, backgroundColor:'#fff'}, 0)
    .to('.nav-toggler .line3', {width: 40, y: '-8', rotate: '-45', backgroundColor:'#fff'}, 0);

    let modalGrow = gsap.timeline({ paused:true, duration: 0.5})
    .to('header .navbar .modalNav', {duration: .5, width: '100vw', height: '100vh', left:'0%', top: '0%', borderRadius: 0, opacity:1}, 0);

    function handleInteraction(event) {
        event.preventDefault();
        if(openButton.value=="show") {
            
            gsap.to(openButton, {duration:0.5, backgroundColor:'var(--bs-primary)', borderRadius:'50%', scale:0.7});
            gsap.fromTo('.modal-content ul li', {opacity:0, y:30}, {duration:1, opacity:1, y:0, stagger:0.1});
            gsap.fromTo('.modalSVG path', {visibility: 'hidden', opacity:0, y:30}, {visibility: 'visible',duration:1, opacity:1, y:0, stagger:0.1});
            logoSection.classList.remove('nav-shadow');
            openButton.classList.remove('bg-primary');
            openButton.value="hide";

            modalGrow.play();
            navLines.play();

        } else if(openButton.value=="hide") {
            openButton.value="show";
            gsap.to(openButton, {duration:0.5, backgroundColor:'(var(--bs-white))', borderRadius:'0%', scale:1});
            logoSection.classList.add('nav-shadow');
            openButton.classList.add('bg-primary');
            modalGrow.reverse();
            navLines.reverse();
        }
    }
    openButton.addEventListener('touchstart', handleInteraction);
    openButton.addEventListener('click', handleInteraction);

    gsap.from('.is-style-hero div > *:not(.vimeo-wrapper)', { duration:1, opacity:0, y:'50%'});
    gsap.from('header .wp-block-button, header li', { duration:1, opacity:0, y:'50%', stagger: 0.05});
    gsap.from('.navbar .nav-shadow', {duration:1, x:'-100%'});
}
export { navigation as default };