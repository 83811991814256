import { Modal, Carousel } from 'bootstrap';

function galleryLightbox() {
    // check if gutenberg gallery exists
    if (document.querySelector('.wp-block-gallery')) {
        // set image a href to ""
        const imageGrid = document.querySelector(".wp-block-gallery");
        const links = imageGrid.querySelectorAll("a");
        for (const link of links) {
            link.setAttribute("href", "");
        }
        // add lightbox html to page
        const lightbox = document.createElement('div');
        const lightboxMarkup = `
        <div class="modal lightbox-modal" id="lightbox-modal" tabindex="-1">
            <div class="modal-dialog modal-fullscreen">
                <div class="modal-content">
                <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
                <div class="modal-body">
                    <div class="container-fluid p-0">
                    </div>
                </div>
                </div>
            </div>
        </div>`
        lightbox.innerHTML = lightboxMarkup;
        document.body.appendChild( lightbox );

        const imgs = imageGrid.querySelectorAll("img");
        const lightboxModal = document.getElementById("lightbox-modal");
        const bsModal = new Modal(lightboxModal);
        const modalBody = document.querySelector(".modal-body .container-fluid");

        for (const link of links) {
        link.addEventListener("click", function (e) {
            e.preventDefault();
            const currentImg = link.querySelector("img");
            const lightboxCarousel = document.getElementById("lightboxCarousel");
            if (lightboxCarousel) {
            const parentCol = link.parentElement.parentElement;
            const index = [...parentCol.parentElement.children].indexOf(parentCol);
            const bsCarousel = new Carousel(lightboxCarousel);
            bsCarousel.to(index);
            } else {
            createCarousel(currentImg);
            }
            bsModal.show();
        });
        }

        function createCarousel(img) {
            const markup = `
                <div id="lightboxCarousel" class="carousel slide carousel-fade" data-bs-ride="carousel" data-bs-interval="false">
                    <div class="carousel-inner">
                        ${createSlides(img)}
                    </div> 
                    <button class="carousel-control-prev" type="button" data-bs-target="#lightboxCarousel" data-bs-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Previous</span>
                    </button>
                    <button class="carousel-control-next" type="button" data-bs-target="#lightboxCarousel" data-bs-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Next</span>
                    </button>
                </div>`;

        modalBody.innerHTML = markup;
        }

        function createSlides(img) {
        let markup = "";
        const currentImgSrc = img.getAttribute("src");

        for (const img of imgs) {
            const imgSrc = img.getAttribute("src");
            const imgAlt = img.getAttribute("alt");

            markup += `
            <div class="carousel-item${currentImgSrc === imgSrc ? " active" : ""}">
                <img src=${imgSrc} alt=${imgAlt}>
            </div>
            `;
        }

        return markup;
        }
    }
}

export { galleryLightbox as default };