import domReady from '@roots/sage/client/dom-ready';

import 'bootstrap';

import navigation from './components/navigation';
import stickyTop from './components/stickyTop';
import closeModal from './components/closeModal';
import scrollTop from './components/scrollTop';
import galleryLightbox from './components/galleryLightbox';

/**
 * app.main
 */
domReady(async () => {
  // application code
  navigation();
  galleryLightbox();
  scrollTop();
  stickyTop();
  closeModal();
});

/**
 * Initialize
 *
 * @see https://webpack.js.org/api/hot-module-replacement
 */
import.meta.webpackHot?.accept(console.error);