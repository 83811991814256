function scrollTop() {
    let currentScrollTop = 1;
    let navbarTop = document.querySelector('.top-list');
    window.onscroll = function (){
        let a = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

        currentScrollTop = a;
        if (currentScrollTop > 150) {
            navbarTop.classList.remove('d-xl-flex');
        } else {
            navbarTop.classList.add('d-xl-flex');
        }
    };
}
export { scrollTop as default };